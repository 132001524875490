@tailwind base;
@tailwind components;
@tailwind utilities;

.logo-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Початковий розмір */
    opacity: 1; /* Початкова непрозорість */
  }
  50% {
    transform: scale(1.2); /* Збільшення розміру до 110% */
    opacity: 0.8; /* Зменшення непрозорості */
  }
  100% {
    transform: scale(1); /* Повернення до початкового розміру */
    opacity: 1; /* Повернення до початкової непрозорості */
  }
}
